$background-color: #fefcfc;
$border: 1px solid #e3d4d4;
$text-color: #080707 !important;

.is-required-indicator:before {
  content: " \25CF";
  font-size: 15px;
  color: #ffdd00;
  margin-right: 0.5em;
}

.not-required-indicator:before {
  content: " \25CF";
  font-size: 15px;
  color: #91ce73;
  margin-right: 0.5em;
}

.variable-collapse-header {
  background-color: #fefcfc;
  color: #080707;
  padding-left: 1em;
}

.antd-default-collapse-container.collapse-container {
  .anticon.anticon-right.ant-collapse-arrow {
    color: $text-color;
  }
  .ant-collapse-header {
    background-color: $background-color !important;
    color: $text-color !important;
  }

  div.ant-collapse-content {
    > div.ant-collapse-content-box {
      padding: 1.5em 5em;
      white-space: nowrap;
      font-size: 10px;
      overflow: auto;
    }
  }
}

.ant-popover.ant-popover-placement-bottom {
  left: 385px;
  top: 350px;
  min-width: 399px;
  max-height: 250px;
  overflow: scroll;
}

.ant-popover-title {
  text-align: center;
  padding: 1em;
  font-weight: 600;
}

ul.req-variable-ul {
  padding-inline-start: 20px !important;
  list-style: none;
  li {
    list-style: none;
    .not-required-indicator:before {
      font-size: 8px;
    }
    .is-required-indicator:before {
      font-size: 8px;
    }
  }
}
