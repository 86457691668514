.launch-container {
  width: 100%;
  height: auto;

  padding: 1em;

  .launch-instance {
    padding: 10px;
    margin: 10px 10px 50px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    cursor: pointer;
    max-width: 100vw;
    overflow-x: scroll;

    &.selected {
      border: 4px solid #40a9ff;
    }
  }

  .launch-collapse-panel-previews {
    width: 100%;
    height: auto;

    display: flex;
    flex-direction: row;

    margin: 1em 0;

    > .dimension {
      flex: initial;
      width: 10em;
      height: auto;
    }

    > .template-previews {
      flex: 1;
      height: auto;

      display: flex;
      flex-direction: column;

      > .preview {
        flex: 1;
      }
    }
  }
}

div.asset-launch-status-modal {
  .content-col {
    border: 0.5px solid #cccccc;
    padding: 1em;
  }
  .content-header {
    border-top: 0.5px solid #cccccc;
    padding: 1em;
  }
  .head-left {
    border-left: 0.5px solid #cccccc;
    padding: 1em;
  }
  .head-right {
    border-right: 0.5px solid #cccccc;
    padding: 1em;
  }
  .head-bot {
    border-bottom: 0.5px solid #cccccc;
    padding: 1em;
  }
}

.asset-launcher-summary-modal {
  .ant-modal-content {
    width: 75em;
    overflow-y: auto;
    height: 61em;
  }
}

.asset-launcher-web-integration-status-modal {
  .ant-modal-content {
    width: 75em;
    overflow-y: auto;
    height: 57em;
  }
}

.tab-container-content-section {
  padding-bottom: 0 !important;
}

.status-buttons {
  float: left;
  margin-left: 1em;
}

.status-modal-col {
  height: 8em;
}

.status-modal-col-header {
  height: 4em;
}

.launcher-asset-container {
  padding: 10px;
  margin: 10px 10px 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  max-width: 100vw;
  overflow-x: scroll;
}
.selected-launchers-container {
  border: 4px solid #40a9ff;
  cursor: pointer;
}

.loading-launchers-container {
  cursor: not-allowed;
}

.disabled-collapsable {
  > div {
    opacity: 0.5;
  }
}

.add-new-web-integration {
  width: 100%;
  height: 7em;
  font-size: 1.5rem;
  margin: 1em 0;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  box-sizing: border-box;
  display: flex;
  &:hover {
    cursor: pointer;
    background: lightgray;
    font-weight: bold;
  }
  > span {
    flex: 1;
    margin: 0.5em;
    display: block;
    border: 1px dashed #0094d0;
    display: flex;
    align-items: center;
    justify-content: center;

    user-select: none;
  }
}

.web-integration-uploader {
  width: 750px;
  max-width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
}
