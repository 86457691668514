.host {
  position: relative;
  margin: 0 4px;
  border: 1px solid #d5cac9;
  box-shadow: 0 0 4px 0 rgba(black, 0.38);

  &[data-selected-tpl-card="true"] {
    box-shadow: 0 0 0 4px #40a9ff;

    .check {
      border: 3px solid #40a9ff;
    }
  }
}

.cardOverlay {
  position: absolute;
  inset: 0;
  z-index: 1;

  &:hover {
    cursor: pointer;
  }
}

.previewWrapper {
  padding-bottom: 1.5em;
}

.assetPlaceholder {
  min-height: 20em;
}

.selectInstance {
  display: inline-block;
  position: relative;
  z-index: 2;
  margin: 14px 0 0 14px;
}

.cardControls {
  display: flex;
  gap: 8px;
  position: absolute;
  z-index: 2;
  bottom: -1.75em;
  left: 50%;
  transform: translateX(-50%);
}
