.container {
  background-color: #eeeeee;
  padding-top: 1em;
  height: calc(
    100vh - 48px - 42px - 14px
  ); // 48px = header, 42px = footer, 14px = padding
}

.tableContainer {
  display: flex;
  flex-direction: row;
  height: 90%;
}

.table {
  width: calc(100% - 201px);
  background-color: #fff;
}

.virtualTable {
  height: 100%;
  :global {
    .ant-table-body,
    .ant-spin-nested-loading,
    .ant-spin-container,
    .ant-table-fixed-header,
    .ant-table-container {
      height: 100%;
    }
  }
}

.undoMessage {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  > div {
    padding: 0 8px;
    background-color: #f6ffed;
    border: 1px solid #b7eb8f;
  }

  > div > div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 42px;
  }
}

.undoMessage {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  > div {
    padding: 0 8px;
    background-color: #f6ffed;
    border: 1px solid #b7eb8f;
  }

  > div > div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 42px;
  }
}
