.offer-selection {
  width: 100%;
  max-height: 500px;
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;

  > .offer-selection-choices {
    // ul.offer-type-selection {
    //   overflow-x: scroll;
    //   list-style-type: none;
    //   padding-left: 1em;
    //   margin: 1em 0;
    // }

    .ant-select-multiple .ant-select-selector .ant-select-selection-item {
      min-height: 24px;
    }
  }

  .purchase-type-select {
    width: 100%;

    ul {
      li {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
  }
}
