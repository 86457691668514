.radioSelectionTitle {
  flex: 1;
  color: rgba(0, 0, 0, 0.45);
  font-weight: bold;
  margin-top: 2%;
}

.sectionTopSpacing {
  padding-top: 5%;
}

.exportRow {
  flex-direction: column;
  justify-content: space-between;
  row-gap: 20px;
}

.exportOptions {
  margin-left: 2%;
}

.assetBatchOptions {
  margin-top: 2%;
  margin-left: 2%;
}

.requiredField {
  color: red;
}
