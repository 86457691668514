@use "../mixins";

.host {
  display: grid;
  grid-template-rows: 430px auto;
  gap: 2em;
  padding: 2em;
  background-color: #f0f0f0;
}

.preview {
  display: flex;
  justify-content: center;
  align-items: center;
}

.img {
  @include mixins.imgBackground;
  max-width: 100%;
  max-height: 100%;

  [data-mixed="true"] & {
    max-width: 300px;
    margin: 0 auto;
  }
}

.details {
  > div {
    display: grid;
    grid-template-columns: 120px auto;
    margin-bottom: 0.5em;
  }
}
