.form {
  padding: 1em;

  > div {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  }

  &[data-disabled="true"] {
    opacity: 0.75;
    pointer-events: none;
  }
}
