.footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filterInput {
  width: 100%;
}

.saveFiltersCheckboxContainer {
  background: #f5f5f5;
  width: 100%;

  > .checkbox {
    padding: 16px 24px;
    min-height: 54px;
  }

  > .divider {
    width: 100%;
    height: 1px;
    background: #e0e0e0;
  }

  > .filtersNameInput {
    padding: 12px 24px;

    width: 100%;
    height: calc(110px - 54px);

    background: inherit;
    > * {
      width: 100%;
    }
  }
}

.savedFilters {
  background: #f5f5f5;
  padding: 20px;
}

.savedFilterOption {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    > .deleteButton {
      display: block;
    }
  }

  > .deleteButton {
    display: none;
  }
}
