.toolbar {
  display: flex;
  flex-wrap: wrap;
  padding: 0 12px;
  align-items: center;
  gap: 8px;
}

.shareButton {
  display: flex;
  justify-content: center;
  align-items: center;
}

.toolbarSearch {
  flex-grow: 1;
  margin-left: 1px;

  & > div {
    padding: 0;
  }
}
