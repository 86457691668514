@use "../../mixins";

.host {
  > :first-child {
    display: flex;
    align-items: center;
    padding: 0 1em;
    height: 3em;
  }
}
.name {
  cursor: pointer;
  color: #1890ff;
}

.image {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 170px;
  padding: 1em;

  &[data-selectable="false"] {
    pointer-events: none;
    opacity: 0.15;
    filter: grayscale(1);
  }

  > img {
    max-width: 100%;
    max-height: 100%;
    @include mixins.imgBackground;
  }
}
