.divider {
  margin: 0;
}

.host {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0.5em 12px;

  > * {
    margin-right: 0.5em;
  }
}
