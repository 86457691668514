.table {
  padding: 0;
  padding-left: 48px;
}

.row {
  background-color: #fbfbfb;
  td {
    padding: 0;
  }
}
