$text-color: #4d4b4b;
$header-icon-color: rgba(46, 140, 213, 0.65);

.disclosure-title-text {
  color: $text-color;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.05px;

  > .number-counter {
    flex: initial;

    display: inline-block;
    background: #f47920;
    padding: 1px 15px;
    font-size: 0.7rem;
    border-radius: 15px;
    margin: 0 0.5em;
    color: #ffffff;
  }
}

.disclosure-title-tip {
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: -0.04px;
  color: #4d4b4b;
}

.disclosure-collapse-container.collapse-container {
  .ant-collapse-header {
    background-color: #e1ebf1 !important;
    color: rgba(0, 0, 0, 0.75) !important;
    font-weight: 500;
  }

  .anticon.anticon-edit.ant-collapse-arrow {
    color: #0092d0;
  }

  .anticon.anticon-save.ant-collapse-arrow {
    color: #0092d0;
  }

  div.ant-collapse-content {
    > div.ant-collapse-content-box {
      white-space: nowrap;
      font-size: 10px;
    }
    ::-webkit-scrollbar {
      width: 0px; /* Remove scrollbar space */
      background: transparent; /* Optional: just make scrollbar invisible */
    }
  }

  input {
    background-color: #ffffff !important;
    color: rgba(0, 0, 0, 0.65) !important;
    border: none !important;
  }
}

.disclosures-column {
  background: #e1ebf1;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  border: solid 2px #0092d0;
  width: 100%;
}

.exceptions-column {
  max-height: 388px;
  overflow-y: auto;
  padding: 1.5em;
  background: #ffffff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  border: solid 2px #0092d0;
}

.exceptions-select-oem-content {
  padding-top: 3em;
  text-align: center;
}

.exceptions-select-oem-header-divider {
  margin-top: 0.5em;
}

.exlcusion-oem-select-row {
  padding-right: 1em;
  padding-left: 1em;
}

.exlcusion-oem-select-item {
  border: none !important;
}

.exlusion-oem-select-item:hover {
  background-color: rgba(237, 211, 48, 0.57);
}

.ant-btn.exlcusion-oem-select-item.ant-btn-block:hover {
  background-color: rgba(237, 211, 48, 0.57);
  font-weight: 600;
  color: #666666;
}

div.ant-popover.ant-popover-placement-bottom {
  overflow-x: hidden;
  padding-top: 0;
  ::-webkit-scrollbar {
    width: 0px; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
  .ant-popover-inner-content {
    .ant-menu.ant-menu-light.ant-menu-root.ant-menu-vertical {
      border-right: none;
    }
    overflow-y: scroll;
  }
}

.number-at-price-input-col {
  text-align: center;
  padding-left: 3em;
  padding-right: 3em;
}

.ant-collapse.ant-collapse-icon-position-right.collapse-container {
  div.ant-collapse-content {
    > div.ant-collapse-content-box {
      overflow: auto;
    }
  }
}
