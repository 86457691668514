.previewImage {
  position: relative;

  .image {
    display: flex;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 28px;
    width: 400px;
    height: 400px;
    background: rgba(0, 0, 0, 0.5);
    transition: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    z-index: 100;

    .buttonsRow {
      display: flex;
      gap: 16px;

      .button {
        border: none;
        color: white;
      }
    }

    .infoRow {
      display: flex;
      gap: 8px;

      .text {
        color: white;
      }
    }
  }
}
