.host {
  > :first-child {
    display: flex;
    align-items: center;
    padding: 0 1em;
    height: 3em;
  }
}

.icon {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 170px;
  font-size: 70px;
  background: lightgray;
  color: #1890ff;
}
