$spacing: 12px;

.drawer {
  .ant-drawer-body {
    padding: 0;
  }

  .topBarControls {
    & button {
      & svg,
      & span {
        width: 100%;
        height: 100%;
      }

      & .moreOptionsControl {
        width: 10px;
        height: 10px;
      }

      &.moreOptionsButton {
        width: 12px;
      }
    }
  }

  .content {
    height: 100%;
    width: 100%;

    > div {
      padding: $spacing;
    }
    .editorColumn {
      position: relative;
      background-color: #f5f5f5;

      .controls {
        position: absolute;
        flex-direction: column;

        bottom: $spacing;
        left: $spacing;

        > button {
          margin-left: 0;
        }
      }
    }
  }

  .spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}
