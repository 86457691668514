.lifestyle-image-thumbnail-container {
  flex: 1;
  margin: 0 0.2em;

  padding: 3px;

  position: relative;

  box-sizing: border-box;

  &:hover,
  &.selected {
    cursor: pointer;

    border: 2px solid #39a2d7;
    border-radius: 5px;

    box-sizing: border-box;

    > div.layover {
      display: block;

      background: rgba(0, 0, 0, 0.5);

      position: absolute;
      top: 3px;
      left: 3px;
      bottom: 3px;
      right: 3px;

      opacity: 1;

      border-radius: 5px;

      > .button-container {
        position: absolute;

        top: 50%;
        left: 50%;

        transform: translate(-50%, -50%);

        display: flex;
        flex-direction: row;
        align-items: center;

        > * {
          flex: 1;
          margin: 0 0.2em;
        }
      }
    }
  }

  > div.layover {
    display: none;
  }

  > img.lifestyle-image-thumbnail {
    width: 100%;
    height: 15em;
    object-fit: cover;

    border-radius: 0.5em;
  }

  > h4.theme-image-name {
    padding: 0 0.3em;
    color: #000000b6;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
