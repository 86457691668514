div.ant-popover.ant-popover-placement-topLeft,
div.ant-popover.ant-popover-placement-topRight {
  div.ant-popover-inner {
    border-style: none !important;
    border-radius: 5px;
    padding: 12px 16px !important;
  }
}

.asset-builder-container {
  width: 100%;
  padding-bottom: 30px;

  div.asset-builder-body-container {
    height: 100%;
    display: flex;
    flex-direction: row;
    padding: 0 2em;

    div.filter-section {
      div.ant-tabs-nav .ant-tabs-nav-list {
        width: 100%;
        position: relative;
      }

      div.ant-tabs-nav .ant-tabs-nav-list div:first-child div:nth-child(2) {
        position: absolute;
        right: 0;
      }
    }
  }

  div.asset-builder-list-container {
    flex: 3 1 80%;
    padding: 0 0 7em 0;
    margin-left: 1em;

    .offer-list-container-tab {
      div.ant-tabs-nav .ant-tabs-nav-list {
        width: 100%;
        position: relative;
      }

      div.ant-tabs-nav .ant-tabs-nav-list div:first-child div:nth-child(2) {
        position: absolute;
        right: 0;
        padding: 0;
        width: 50%;

        div.offer-list-button-container {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          position: absolute;
          right: 0;
          transform: translateY(-50%);
          top: 50%;

          > button {
            flex: initial;
            margin: 0 0.5em;
            border: 1px solid #d9d9d9;
            color: #676b6c;
            border-radius: 0;
            height: 2.4em;
            line-height: 2.4em;
          }

          span.search-container {
            height: 2.4em;
            flex: initial;
            display: flex;

            > span:nth-child(1) {
              display: flex;
              justify-content: flex-end;
              height: 2.4em;
              line-height: 2.4em;

              > span.ant-input-suffix {
                > .anticon {
                  margin: 0;
                }
              }

              > input {
                border-radius: 0;
                background-color: transparent;
                color: #676b6c;
              }
            }

            span.search-plus-button {
              flex: initial;

              > button {
                height: 100%;
                background: transparent;
                border-radius: 0;

                > .anticon {
                  margin: 0;
                  color: #7e8589;
                }
              }
            }
          }
        }
      }
    }
  }

  .action-button-header {
    margin-left: 4px !important;
    background-color: #1890ff !important;

    &:hover {
      background-color: #1890ff !important;
      opacity: 0.75;
    }
  }

  .action-button-header-disabled {
    > button {
      background-color: #1890ff !important;
    }
  }

  /* Order Table's Action buttons sub table */

  /*
    In Safari, the action button table overlaps by default.
    Adding max-width fixes this issue
  */
  .ant-table-fixed-right {
    max-width: 23em !important;
  }

  /* Since the max-width is replaced, we need to return it in Chrome */
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    .ant-table-fixed-right {
      min-width: 5em !important;
    }
  }

  /* The same applies to Firefox */
  @-moz-document url-prefix() {
    .ant-table-fixed-right {
      min-width: 5em !important;
    }
  }
}
