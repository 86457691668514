.ad-import-table-container {
  .ant-table-cell {
    min-width: 150px;
    max-width: 500px;
  }
  .ant-table-selection-column {
    min-width: 30px;
  }
  .cell-with-error {
    background-color: #ffcaca !important;
  }
}

.ad-table-column-modal {
  .ant-modal-body {
    padding: 1em;
    display: flex;
    flex-direction: row;
    .ant-checkbox-group {
      .ant-checkbox-wrapper {
        width: 100%;
        margin: 0 0 0 1em;
        padding: 0;
      }
    }
    .ant-divider {
      align-self: center;
      height: 20em;
    }
  }
}
