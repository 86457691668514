.notification {
  text-align: center;
  position: fixed;
  z-index: 99999;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  &:hover {
    cursor: pointer;
  }
}

.header {
  padding: 0;
  background-color: white;
  height: 48px;
  z-index: 1000;
  box-shadow: 0 1.5px 1.5px 0 rgba(0, 0, 0, 0.25);
  width: 100vw;
}

.row {
  height: 48px;
}

.col {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.footer {
  padding: 8px 12px;
  border-top: solid 1px rgba(0, 0, 0, 0.1);
  & > div {
    text-align: center;
    & > div > div > svg {
      margin-top: 6px;
    }
  }
}

.layout {
  width: 100%;
  height: 100vh;
}

.flexSiderDiv {
  height: 100%;
  display: flex;
  flex-flow: column;
}

.sider {
  position: absolute;
  height: 95vh;
  z-index: 9999;
}

.siderHeader {
  flex: 0 1 auto;
}

.siderBody {
  flex: 1 1 auto;
}

.siderFooter {
  flex: 0 1 55px;
  text-align: center;

  .patentSymbol {
    margin-bottom: 0.5rem;
  }
}

.animatedDivWrapper {
  padding-top: 12px;
  padding-left: 16px;
}

.layoutContent {
  width: 100%;
  overflow: auto;
}

.divider {
  height: 24px;
  margin: 0 12px;
}

.hiddenNewVersionAlert {
  opacity: 0;
  pointer-events: none;
}
