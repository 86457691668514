.clientIconsCol {
  width: 100%;
  margin-right: 12px;
  justify-content: flex-end;
}

.shareButton {
  margin: 0 1em;
}

.row {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  gap: 1em;

  > * {
    flex: 1;
    flex-basis: fit-content;
  }

  > .menu-icon {
    width: auto;
    height: 100%;
    flex: initial;

    display: flex;
    align-items: center;
    justify-content: center;

    border-right: 1px solid lightgray;
  }
}
