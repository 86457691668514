.row {
  padding: 0.7em;
  justify-content: flex-start;
  width: 100%;
  display: flex;

  > * {
    flex: 1 !important;
  }

  &:hover {
    background-color: #e6f7ff;
  }

  .input {
    width: 100%;
    border: 1px solid #d9d9d9;
    background-color: #ffffff;
  }
  
  .inputError {
    width: 100%;
    border: 1px solid #ff0000;
    background-color: #ffffff;

    & :global {
      .ant-select-selector {
        border: none;
        border-right-width: 1px !important;
      }
    }
  }

  .errorDesc {
    color: #ff0000;
  }
}

.logoSelectName {
  padding-left: 10px;
}
