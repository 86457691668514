.build-asset-list {
  position: relative;
  padding: 0 0em;

  .ant-collapse-content-box {
    padding: 0 !important;
  }

  div[class*="build-collapse-for-size-"] {
    .template-card-container {
      margin: 2.5em 0;
    }

    .size-ctn {
      max-height: calc(100vh - 94px);
      overflow-y: auto;
      top: 1em;
    }

    .add-new-instance {
      width: 100%;
      height: 7em;

      font-size: 1.5rem;

      margin: 1em 0;

      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);

      box-sizing: border-box;

      display: flex;

      &:hover {
        cursor: pointer;
        background: lightgray;
        font-weight: bold;
      }

      > span {
        flex: 1;
        margin: 0.5em;
        display: block;

        border: 1px dashed #0094d0;

        display: flex;
        align-items: center;
        justify-content: center;

        user-select: none;
      }
    }
  }

  background-color: transparent;
  .btn-container {
    position: absolute;
    top: 55%;
    right: 10px;
    transform: translateY(-50%);
    font-size: 1.25em;

    > * {
      width: auto;
      height: 100%;

      display: inline-block;

      margin: 0 1em;

      > .label {
        margin: 0 0.5em;
        user-select: none;
      }
    }

    .ant-btn {
      color: #fff;
    }
  }

  .ant-badge-count {
    background-color: orange;
    position: relative;
    top: -2px;
    left: 5px;
  }
}
