.collapseContainer {
  & :global {
    .ant-collapse-header {
      background-color: #fafafa;
    }
    .ant-collapse-content-box {
      background-color: white;
      border-top: 1px solid #d9d9d9;
    }
  }
}

.labelVariable {
  font-size: 14px;
  font-weight: 600;
  margin-top: 14px;
  color: #a0a0a0;
}

.input {
  width: 100%;
}

.required {
  color: #ff0000;
  margin-left: 4px;
}

.dropdownContainer {
  padding-left: 20px;
}

.compVariablesContainer {
  height: 100%;
  overflow-y: auto;
}

.holderIcon {
  margin-right: 5px;
  margin-left: -10px;
  color: rgba(0, 0, 0, 0.45);
}

.rightArrow {
  margin-left: auto;
  margin-right: 10px;
}

.customPanelHeader {
  display: flex;
  align-items: center;
}
