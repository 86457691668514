$border-color: #727272;
$background-color: #454545;

$hover-background: rgba(0, 121, 255, 1);

div.font-weight-size-property {
  div.ant-select-selection__rendered {
    max-width: 135px !important;
  }
}

div.property-container {
  background: transparent;
  display: flex;
  flex-direction: column;

  div.font-alignment-property {
    color: white;

    > div.alignments {
      display: flex;
      height: 2em;

      border: 1px solid $border-color;

      > span {
        flex: 1;
        position: relative;

        > .anticon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      > span:hover {
        background: $hover-background;
        cursor: pointer;
      }
    }
  }

  div.font-script-property {
    color: white;
    > div.script-container {
      width: 50%;
      height: 2em;
      display: flex;
      border: 1px solid $border-color;

      > div.base {
        flex: 1;

        position: relative;

        line-height: 2em;
        font-weight: bold;
        text-align: center;

        > div.superscript {
          position: absolute;
          font-size: 0.4rem;

          top: 50%;
          left: 60%;
          transform: translateY(-60%);
        }

        > div.subscript {
          position: absolute;
          font-size: 0.4rem;

          bottom: 50%;
          left: 60%;
          transform: translateY(60%);
        }
      }

      > div.base:hover {
        background: $hover-background;
        cursor: pointer;
      }
    }
  }
}
