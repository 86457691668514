.container {
  background-color: #fff;
  border-right: 1px solid #f0f0f0;
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 8px;
  height: 40px;
  border-bottom: 1px solid #f0f0f0;
  font-weight: bold;
  font-size: 14px;
  padding-left: 12px;
}

.panel {
  width: 200px;
  background-color: #fff;
}

.undoMessage {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  > div {
    padding: 0 8px;
    background-color: #f6ffed;
    border: 1px solid #b7eb8f;
  }

  > div > div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 42px;
  }
}
