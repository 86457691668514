.popoverItem {
  margin-top: 10px;
}

.appVersion {
  float: right;
  font-size: 75%;
  font-weight: 100;
  margin-top: 2.75em;
}
