.layerItemContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  cursor: pointer;

  .hoverActions {
    cursor: pointer;
    margin-left: auto;
    visibility: hidden;

    .lockedIcon {
      color: #ff4d4f;
    }
  }

  &:hover {
    background-color: rgba(220, 220, 220, 0.2);

    .holderIcon {
      visibility: visible;
    }

    .hoverActions {
      visibility: visible;
    }
  }

  .holderIcon {
    visibility: hidden;
  }

  .inputContainer {
    min-height: 32px;
    padding: 5px 0;
    pointer-events: none;
    & input {
      padding-left: 5px;
    }
  }
}

.highlighted,
.highlighted:hover {
  background-color: #e6f7ff;
}

.editing {
  .inputContainer {
    pointer-events: auto;
  }
}
