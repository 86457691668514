.host {
  position: relative;

  > :first-child {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(min(10rem, 100%), 1fr));
    gap: 1em;

    &[data-loading="true"] {
      opacity: 0.5;
    }
  }

  > :last-child {
    position: absolute;
    inset: 0;
    margin: 50px 0;

    &:empty {
      display: none;
    }
  }
}
