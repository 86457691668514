.helpContent {
  width: 270px;
  height: 170px;
  padding: 12px 4px;
}

.helpTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  
  > span {
    font-size: 16px; 
    line-height: 24px;
  }:nth-child(1) {
    font-weight: 600;
  }:nth-child(2) {
    cursor: pointer;
  }
}

.helpButtons {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 90px;
  justify-content: space-between;

  > button {
    width: 100%;
    height: 38px;
    text-align: start;
  }
}