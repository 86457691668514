$not-selected-background-color: lightgray;
$selected-text-color: #32c5ff;

.template-selection {
  width: 100%;
  height: 100%;

  > input.template-search-by-input {
    background: transparent;
    border: inherit;
  }

  > .template-type-selection-tabs {
    height: 3em;
    line-height: 3em;

    display: flex;
    flex-direction: row;

    > .template-type-tab {
      flex: 1;

      text-align: center;

      background: white;

      &:not(.selected) {
        background: $not-selected-background-color;
        opacity: 0.9;
      }

      &:hover {
        cursor: pointer;
        background: white;
      }
    }
  }

  > .content {
    > ul.template-list {
      width: 100%;
      padding: 0;

      list-style: none;

      > li.template-list-item {
        display: flex;
        flex-direction: column;
        align-items: center;

        padding: 1em 0;

        .template-thumbnail-container {
          width: 100%;
          max-height: 300px;
          padding: 5px;
          box-sizing: border-box;

          &:hover,
          &.selected {
            cursor: pointer;

            border: 2px solid #39a2d7;
            border-radius: 5px;

            box-sizing: border-box;
          }

          > img.template-thumbnail {
            width: 100%;
            max-height: 200px;
            object-fit: contain;

            border-radius: 5px;
          }

          > .template-name {
            margin: 0.7em 0;
          }
        }
      }
    }
  }
}
