.headerContainer {
  display: inline-flex;
  align-items: center;
  width: 100%;
}

.mediaDurationInput {
  width: 60px;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 0.1em;
  margin-left: auto;
}
